if ('undefined' === typeof window) {
    importScripts('https://www.gstatic.com/firebasejs/7.9.1/firebase-app.js');
    importScripts('https://www.gstatic.com/firebasejs/7.9.1/firebase-messaging.js');
    firebase.initializeApp({
        apiKey: API_KEY,
        projectId: FIREBASE_PROJECT_ID,
        messagingSenderId: GCM_SENDER_ID,
        appId: APP_ID,
    });
    const messaging = firebase.messaging();
}
